import { Table, ActionIcon, Group } from '@mantine/core';
import { IconPencil, IconTrash } from '@tabler/icons';
import { Link } from 'react-router-dom';

import { useNews } from '../hooks/useNews'; 

function Collections() {
  const { news, deleteNews } = useNews();
  
  const rows = news.map((element)  => {
    return (
      <tr key={element._id.toString()}>
        <td>{element._id.toString()}</td>
        <td>{element.title}</td>
        <td>{element.type}</td>
        <td>
          <Group spacing={0} position="right">
            <Link to={`/news/${element._id.toString()}`}>
              <ActionIcon>
                <IconPencil size={16} stroke={1.5} />
              </ActionIcon>
            </Link>
            <ActionIcon color="red" onClick={() => deleteNews(element._id)}>
              <IconTrash size={16} stroke={1.5} />
            </ActionIcon>
          </Group>
        </td>
      </tr>
    )
  });
  
  return (
    <Table>
      <thead>
          <tr>
          <th>ID</th>
          <th>Title</th>
          <th>Type</th>
          <th />
          </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
  }
  
export default Collections;