import React from "react";
import { useWatch } from "./useWatch";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../api/RealmApp";
import realmJson from "../api/realm.json";
import * as Realm from "realm-web";

import {
  addValueAtIndex,
  replaceValueAtIndex,
  updateValueAtIndex,
  removeValueAtIndex,
  getItemIndex,
} from "./utils";

export function useHalls() {
  // Set up a list of todos in state
  const realmApp = useRealmApp();
  const [halls, setHalls] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // Get a client object for the todo task collection
  const hallCollection = useCollection({
    cluster: realmJson.dataSourceName,
    db: "zev",
    collection: "ExhibitionHall",
  });

  // Fetch all todos on load and whenever our collection changes (e.g. if the current user changes)
  React.useEffect(() => {
    hallCollection.find({_partition: realmApp.currentUser.id}).then((fetchedHalls) => {
        setHalls(fetchedHalls);
      setLoading(false);
    });
  }, [hallCollection]);

  // Use a MongoDB change stream to reactively update state when operations succeed
  useWatch(hallCollection, {
    onInsert: (change) => {
        setHalls((oldHalls) => {
        if (loading) {
          return oldHalls;
        }
        const idx =
          getItemIndex(oldHalls, change.fullDocument) ?? oldHalls.length;
        if (idx === oldHalls.length) {
          return addValueAtIndex(oldHalls, idx, change.fullDocument);
        } else {
          return oldHalls;
        }
      });
    },
    onUpdate: (change) => {
        setHalls((oldHalls) => {
        if (loading) {
          return oldHalls;
        }
        const idx = getItemIndex(oldHalls, change.fullDocument);
        return updateValueAtIndex(oldHalls, idx, () => {
          return change.fullDocument;
        });
      });
    },
    onReplace: (change) => {
        setHalls((oldHalls) => {
        if (loading) {
          return oldHalls;
        }
        const idx = getItemIndex(oldHalls, change.fullDocument);
        return replaceValueAtIndex(oldHalls, idx, change.fullDocument);
      });
    },
    onDelete: (change) => {
        setHalls((oldHalls) => {
        if (loading) {
          return oldHalls;
        }
        const idx = getItemIndex(oldHalls, { _id: change.documentKey._id });
        if (idx >= 0) {
          return removeValueAtIndex(oldHalls, idx);
        } else {
          return oldHalls;
        }
      });
    },
  });

  // Given a draft todo, format it and then insert it
  const saveHall = async (draftExhibition) => {
    draftExhibition._partition = realmApp.currentUser.id;
    try {
      await hallCollection.insertOne(draftExhibition);
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a exhibition multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    }
  };

  const getHallsByid = async (id) => {
    const current = await hallCollection.findOne({_id: new Realm.BSON.ObjectID(id)})
    return current
  }

  return {
    loading,
    halls,
    saveHall,
    getHallsByid
  };
}
