import React from "react";
import { useWatch } from "./useWatch";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../api/RealmApp";
import realmJson from "../api/realm.json";

import {
  addValueAtIndex,
  replaceValueAtIndex,
  updateValueAtIndex,
  removeValueAtIndex,
  getItemIndex,
} from "./utils";

export function useMuseums() {
  // Set up a list of todos in state
  const realmApp = useRealmApp();
  const [museums, setMuseums] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // Get a client object for the todo task collection
  const museumCollection = useCollection({
    cluster: realmJson.dataSourceName,
    db: "zev",
    collection: "Museum",
  });
  console.log(realmApp.currentUser.id);

  // Fetch all todos on load and whenever our collection changes (e.g. if the current user changes)
  React.useEffect(() => {
    museumCollection.find({ _partition: realmApp.currentUser.id}).then((fetchedMuseums) => {
      setMuseums(fetchedMuseums);
      setLoading(false);
    });
  }, [museumCollection]);

  // Use a MongoDB change stream to reactively update state when operations succeed
  useWatch(museumCollection, {
    onInsert: (change) => {
      setMuseums((oldMuseums) => {
        if (loading) {
          return oldMuseums;
        }
        const idx =
          getItemIndex(oldMuseums, change.fullDocument) ?? oldMuseums.length;
        if (idx === oldMuseums.length) {
          return addValueAtIndex(oldMuseums, idx, change.fullDocument);
        } else {
          return oldMuseums;
        }
      });
    },
    onUpdate: (change) => {
      setMuseums((oldMuseums) => {
        if (loading) {
          return oldMuseums;
        }
        const idx = getItemIndex(oldMuseums, change.fullDocument);
        return updateValueAtIndex(oldMuseums, idx, () => {
          return change.fullDocument;
        });
      });
    },
    onReplace: (change) => {
      setMuseums((oldMuseums) => {
        if (loading) {
          return oldMuseums;
        }
        const idx = getItemIndex(oldMuseums, change.fullDocument);
        return replaceValueAtIndex(oldMuseums, idx, change.fullDocument);
      });
    },
    onDelete: (change) => {
      setMuseums((oldMuseums) => {
        if (loading) {
          return oldMuseums;
        }
        const idx = getItemIndex(oldMuseums, { _id: change.documentKey._id });
        if (idx >= 0) {
          return removeValueAtIndex(oldMuseums, idx);
        } else {
          return oldMuseums;
        }
      });
    },
  });

  // Given a draft todo, format it and then insert it
  const saveMuseum = async (draftMuseum) => {
    draftMuseum._partition = realmApp.currentUser.id;
    try {
      await museumCollection.insertOne(draftMuseum);
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a museum multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    }
  };

  return {
    loading,
    museums,
    saveMuseum,
  };
}
