import { createStyles, Card, TextInput, Text, Stack, Divider, Tabs, Button, LoadingOverlay, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { RichTextEditor } from '@mantine/rte';
import { useEffect, useState, useCallback } from 'react';
import * as Realm from "realm-web";
import { useNavigate, useParams } from 'react-router-dom';
import { DropzoneButton } from '../components/DropZoneButton';
import { useNews } from '../hooks/useNews';
import { DndListHandle } from '../components/DndListHandle';
import { useListState } from '@mantine/hooks';
import { useRealmApp } from '../api/RealmApp';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },
  title: {
    lineHeight: 1,
  },
}));

export function AddNews() {
  let { id } = useParams();
  const realmApp = useRealmApp();
  const { classes } = useStyles();
  const { getNewsById, saveNews } = useNews();
  const navigate = useNavigate();
  const [mnValue, onChangeMN] = useState('');
  const [enValue, onChangeEN] = useState('');
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState('news');
  const [state, handlers] = useListState([]);


  const form = useForm({
    initialValues:{
      title: '',
      title_en: ''
    }
  });

  useEffect(() => {
    if(id) {
      getNewsById(id).then(exh => {
        form.setValues(exh);
        if(exh.content_mn) onChangeMN(exh.content_mn)
        if(exh.content_en) onChangeEN(exh.content_en)
        if(exh.image) handlers.setState(exh.image)
      })
    }
  }, [id])

  const handleImageUpload = useCallback(
    (file) =>
      new Promise((resolve, reject) => {
        // Get a reference to the file
        // Encode the file using the FileReader API
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');
            realmApp.currentUser.callFunction('uploadImageToS3', base64String)
            .then((response) => {
              console.log(response);
              resolve(response.url)
            })
            .catch((e) => {
              console.log(e);
              reject(new Error('Upload failed'))
            });
        };
        reader.readAsDataURL(file);
      }),
    []
  );

  const addNewCollection = async (values) => {
    setVisible(true);
    await saveNews(
      {
        ...values, 
        _id: id ? new Realm.BSON.ObjectID(id) : new Realm.BSON.ObjectID(),
        image: state.filter(x => x.includes('http')),
        type,
        content_mn: mnValue, 
        content_en: enValue, 
      }
    )
    navigate('/news');
  }
  return (
    <div style={{position: 'relative'}}>
      <Card withBorder radius="md" p="xl" className={classes.card}>
        <form onSubmit={form.onSubmit(addNewCollection)}>
        <LoadingOverlay visible={visible} overlayBlur={2} />

          <Text size="xl" className={classes.title} weight={'bold'}>
            Мэдээ нэмэх
          </Text>
          <Divider my="sm" />
          <Text size="md">
            Зурагууд
          </Text>
          { state.length > 0 && <DndListHandle state={state} handlers={handlers} /> }
          <DropzoneButton state={state} handlers={handlers}/>

          <Divider my="sm" />

          <Select
            label="Мэдээний төрөл"
            value={type}
            onChange={setType}
            data={[ 
              {
                value: 'news', label: 'Мэдээ'
              },
              {
                value: 'events', label: 'Арга хэмжээ'
              },
              {
                value: 'exhibit', label: 'Үзэсгэлэн'
              },
              {
                value: 'training', label: 'Сургалт'
              },
              {
                value: "education", label: "Боловсролын хөтөлбөр",
              },
              {
                value: 'research', label: 'Судалгаа'
              },
              {
                value: 'open-positions', label: 'Нээлттэй ажлын байр'
              },
            ]}
          />

          <Divider my="sm" />

          <Tabs defaultValue="mn">
            <Tabs.List grow>
              <Tabs.Tab value="mn">Монгол мэдээлэл</Tabs.Tab>
              <Tabs.Tab value="en">Англи мэдээлэл</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="mn" pt="xs">
            <Stack>
                <TextInput
                  placeholder="Мэдээний нэр"
                  label="Мэдээний нэр"
                  withAsterisk
                  {...form.getInputProps('title')}
                />

                <RichTextEditor 
                  formats={['bold', 'italic', 'underline', 'image', 'video']}
                  controls={[['bold', 'italic', 'underline', 'image', 'video']]}
                  value={mnValue} onChange={onChangeMN} id="rte" styles={{ root: { minHeight: 300 } }} onImageUpload={handleImageUpload}  />

              </Stack>
            </Tabs.Panel>

            <Tabs.Panel value="en" pt="xs">
              <Stack>
              <TextInput
                  placeholder="Мэдээний нэр"
                  label="Мэдээний нэр"
                  withAsterisk
                  {...form.getInputProps('title_en')}
                />
              <RichTextEditor 
                  formats={['bold', 'italic', 'underline', 'image']}
                  controls={[['bold', 'italic', 'underline', 'image']]}
                  value={enValue} onChange={onChangeEN} id="rte" styles={{ root: { minHeight: 300 } }} onImageUpload={handleImageUpload}  />
              </Stack>
            </Tabs.Panel>
          </Tabs>
          <Divider my="sm" />
          <Button fullWidth type='submit'>
            Хадгалах
          </Button>
        </form>
      </Card>
    </div>
  );
}