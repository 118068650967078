import React from "react";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../api/RealmApp";
import realmJson from "../api/realm.json";
import * as Realm from "realm-web";

export function useNews() {
  // Set up a list of todos in state
  const realmApp = useRealmApp();
  const [news, setNews] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // Get a client object for the todo task collection
  const newsCollection = useCollection({
    cluster: realmJson.dataSourceName,
    db: "zev",
    collection: "News",
  });

  // Fetch all todos on load and whenever our collection changes (e.g. if the current user changes)
  React.useEffect(() => {
    newsCollection.find({ _partition: realmApp.currentUser.id}).then((fetchedNews) => {
        setNews(fetchedNews);
      setLoading(false);
    });
  }, [newsCollection]);


  const getNewsById = async (id) => {
    const current = await newsCollection.findOne({_id: new Realm.BSON.ObjectID(id)})
    return current
  }

  // Given a draft todo, format it and then insert it
  const saveNews = async (draftExhibition) => {
    draftExhibition._partition = realmApp.currentUser.id;
    draftExhibition.created_at = new Date();
    try {
      await newsCollection.updateOne({_id: draftExhibition._id}, draftExhibition, { upsert: true });
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a exhibition multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    }
  };

  const deleteNews = async (id) => {
    await newsCollection.deleteOne({_id: id})
  }

  return {
    loading,
    news,
    saveNews,
    getNewsById,
    deleteNews
  };
}
