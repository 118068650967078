import { createStyles, Card, TextInput, Text, Stack, Divider, Tabs, Group, Button, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState, useEffect } from 'react';
import * as Realm from "realm-web";
import { useNavigate, useParams } from 'react-router-dom';
import { Select } from '@mantine/core';

import { useVideos } from '../hooks/useVideos';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },
  title: {
    lineHeight: 1,
  },
}));

export function AddVideos() {
  let { id } = useParams();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [edit, setEdit] = useState(false);

  const { saveVideos, getVideosById, deleteVideos } = useVideos();

  const form = useForm({
    initialValues: {
      title: '',
      url: '',
    },
  });

  useEffect(() => {
    if(id) {
        getVideosById(id).then(exh => {
        if(exh) setEdit(true);
        form.setValues(exh);
      })
    }
  }, [id])

  const addNewVideo = async (values) => {
    setVisible(true);
    await saveVideos(
      {
        ...values, 
        _id: new Realm.BSON.ObjectID(),
      }
    )
    navigate('/videos');
  }

  const deleteVideo = () => {
    deleteVideos(id);
    navigate('/videos');
  }

  return (
    <div style={{position: 'relative'}}>
    <Card withBorder radius="md" p="xl" className={classes.card}>
      <form onSubmit={form.onSubmit(addNewVideo)}>

      <LoadingOverlay visible={visible} overlayBlur={2} />

      <Text size="xl" className={classes.title} weight={'bold'}>
        Видео нэмэх
      </Text>
      <Divider my="sm" />
      <Stack>
        

        <TextInput
          placeholder="Видео нэр"
          label="Видео нэр"
          withAsterisk
          {...form.getInputProps('title')}
        />

        <TextInput
          placeholder="Видео линк"
          label="Видео линк"
          {...form.getInputProps('url')}
        />

        <Divider my="sm" />
        <Button type='submit'>
          Хадгалах
        </Button>

        {edit && <Button color="red" onClick={deleteVideo}> 
          Устгах
        </Button>}

      </Stack>
    </form>
    </Card>
    </div>
  );
}