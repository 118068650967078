import { createStyles, Text, Image, Button, Group, Progress } from '@mantine/core';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { IconCircleCheck, IconRefresh } from '@tabler/icons';
import { useInterval } from '@mantine/hooks';
import { useEffect, useState } from 'react';

const useStyles = createStyles((theme) => ({
  item: {
    borderRadius: theme.radius.md,
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: `${theme.spacing.sm}px ${theme.spacing.xl}px`,
    // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
    marginBottom: theme.spacing.sm,
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },
  progress:{
    position: 'absolute', 
    bottom: 0, 
    width: "100%"
  },
  button: {
    '@media (max-width: 475px)': {
      flex: 1,
    },
  }
}));

export function Item({item, index, handlers}) {
  const { classes, cx } = useStyles();
  const [progress, setProgress] = useState(0);
  
  const interval = useInterval(
    () =>
      setProgress((current) => {
        if (current < 100) {
          return current + 1;
        }
        interval.stop();
        return 0;
      }),
    20
  );

  useEffect(() => {
    item.path && !interval.active && interval.start()
    return interval.stop
  }, [item])

  const imageUrl = item.path ? URL.createObjectURL(item) : item;
  return (
  <Draggable key={item.path || item} index={index} draggableId={item.path || item}>
    {
    (provided, snapshot) => (
      <div style={{position: 'relative'}}>
      <Group
        position='apart'
        className={cx(classes.item, { [classes.itemDragging]: snapshot.isDragging })}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <Group>
          <div>
            {item.path ? <IconRefresh color="gray" size={30} stroke={1.5} /> : <IconCircleCheck color="green" size={30} stroke={1.5} /> }
          </div>
          <Image
            height={100}
            width={100}
            fit="contain"
            radius="md"
            src={imageUrl}
            imageProps={{ onLoad: () => item.path ? URL.revokeObjectURL(imageUrl) : {} }}
          />

          <div>
            <Text>{item.name}</Text>
            <Text color="dimmed" size="sm">
              {item.path ? 'Uploading...' : 'Uploaded'}
            </Text>
          </div>
        </Group>
          <Button className={classes.button} variant="outline" color="red" onClick={() => {handlers.remove(index)}} disabled={item.path && true}>
            Устгах
          </Button> 
      </Group>
        { progress !== 0 &&
          <Progress
            className={classes.progress}
            value={progress}
            radius={'md'}
          />
        }
      </div>
    )}
  </Draggable>
  )
}


export function MultipleExh({ state, handlers }) {
  const items = state.map((item, index) => <Item key={item.path || item} item={item} index={index} handlers={handlers} />)

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) =>
        handlers.reorder({ from: source.index, to: destination?.index || 0 })
      }
    >
      <Droppable droppableId="dnd-list" direction="vertical">
        {
        (provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}