import { Table, ActionIcon, Group, Skeleton, Anchor, Container, Button } from '@mantine/core';
import { IconPencil } from '@tabler/icons';
import { QRCode } from 'react-qrcode-logo';
import BlackQr from '../components/BlackQR';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useVideos } from '../hooks/useVideos';

function Videos() {
  const { loading, videos } = useVideos();


  const rows = videos.map((element)  => {
    return (
      <tr key={element._id.toString()}>
        <td>
            {element.title}
        </td>
        <td>
          {element.url}
        </td>
        <td>
        <Group spacing={0} position="right">
            <Link to={`/videos/${element._id.toString()}`}>
              <ActionIcon>
                <IconPencil size={16} stroke={1.5} />
              </ActionIcon>
            </Link>
          </Group>
        </td>
      </tr>
    )
  });
  
  const rowsLoading = Array(10).fill(0).map((element, index)  => {
    return (
      <tr key={index}>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
      </tr>
    )
  });

  return (
    <Container>
    <Table>
      <thead>
          <tr>
            <th>Видео нэр</th>
            <th>Линк</th>
            <th />
          </tr>
      </thead>
      <tbody>
        {loading ? rowsLoading : rows}
      </tbody>
    </Table>
    </Container>
  );
  }
  
export default Videos;