import { createStyles, Card, TextInput, Text, Stack, Divider, Tabs, Group, Button, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import { RichTextEditor } from '@mantine/rte';
import { useEffect, useState, useCallback } from 'react';
import * as Realm from "realm-web";
import { useNavigate, useParams } from 'react-router-dom';
import { Select } from '@mantine/core';
import { DropzoneButton } from '../components/DropZoneButton';
import { useExhibitions } from '../hooks/useExhibitions'; 
import { useHalls } from '../hooks/useHalls';
import { useMuseums } from '../hooks/useMuseums';
import { useRealmApp } from '../api/RealmApp';
import { DndListHandle } from '../components/DndListHandle';
import { useListState } from '@mantine/hooks';
import { MultipleExh } from '../components/MultipleExh';
import { DropZoneAudio } from '../components/DropZoneAudio';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },
  title: {
    lineHeight: 1,
  },
}));

export function AddExhibition() {
  let { id } = useParams();
  const realmApp = useRealmApp();
  const { classes } = useStyles();
  const { saveExhibition, getExhibitionById } = useExhibitions();
  const { halls } = useHalls();
  const { museums } = useMuseums();
  const navigate = useNavigate();
  const [mnValue, onChangeMN] = useState('');
  const [enValue, onChangeEN] = useState('');
  const [ruValue, onChangeRU] = useState('');
  const [visible, setVisible] = useState(false);
  const [hall, setHall] = useState('');
  const [museum, setMuseum] = useState('');
  const [images, setImages] = useState([]);
  const [state, handlers] = useListState([]);
  const [audio, setAudio] = useListState([]);

  
  const [exhibitions, exhHandlers] = useListState([]);

  const form = useForm({
    initialValues: {
      name: '',
      en_name: '',
      ru_name: '',
      location: '',
      type: '',
      date: '',
      material: '',
      radius: '',
      width: '',
      weight: '',
    },
  });

  useEffect(() => {
    getExhibitionById(id).then(exh => {
      form.setValues(exh);
      if(exh && exh.image) handlers.setState(exh.image)
      if(exh && exh.mn_audio) setAudio.setState([exh.mn_audio])
      setHall(exh.hall_id)
      setMuseum(exh.museum_id)
      onChangeMN(exh.mnValue)
      onChangeEN(exh.enValue)
      onChangeRU(exh.ruValue)
    });
  }, [id])

  const addNewExhibition = async (values) => {
    setVisible(true);
    await saveExhibition(
      {
        ...values, 
        mnValue, 
        enValue, 
        ruValue,
        hall_id: hall,
        museum_id: museum,
        _id: id ? new Realm.BSON.ObjectID(id) : new Realm.BSON.ObjectID(),
        image: state.filter(x => x.includes('http')),
        mn_audio: audio.length ? audio[0] : null
      }
    )
    navigate('/exhibitions');
  }

  const handleImageUpload = useCallback(
    (file) =>
      new Promise((resolve, reject) => {
        // Get a reference to the file
        // Encode the file using the FileReader API
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');
            realmApp.currentUser.callFunction('uploadImageToS3', base64String)
            .then((response) => {
              console.log(response);
              resolve(response.url)
            })
            .catch((e) => {
              console.log(e);
              reject(new Error('Upload failed'))
            });
        };
        reader.readAsDataURL(file);
      }),
    []
  );

  const addNewNegj = () => {
    exhHandlers.append({
      image: '',
      description: '',
      name: ''
    })
  }

  return (
    <div style={{position: 'relative'}}>
    <Card withBorder radius="md" p="xl" className={classes.card}>
      <form onSubmit={form.onSubmit(addNewExhibition)}>

      <LoadingOverlay visible={visible} overlayBlur={2} />

      <Text size="xl" className={classes.title} weight={'bold'}>
        Үзвэр нэмэх
      </Text>
      <Divider my="sm" />
      <Stack>
        
        <TextInput
          placeholder="Үзвэрийн нэр"
          label="Үзвэрийн нэр"
          withAsterisk
          {...form.getInputProps('name')}
        />

        <TextInput
          placeholder="Англи нэр"
          label="Англи нэр"
          withAsterisk
          {...form.getInputProps('en_name')}
        />

        <TextInput
          placeholder="Орос нэр"
          label="Орос нэр"
          withAsterisk
          {...form.getInputProps('ru_name')}
        />

        <Tabs defaultValue="simple">
          <Tabs.List>
            <Tabs.Tab value="simple">Энгийн үзмэр</Tabs.Tab>
            <Tabs.Tab value="multiple">Нэгж үзмэр</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="simple" pt="xs">

          <TextInput
          placeholder="Олдсон газар"
          label="Олдсон газар"
          withAsterisk
          {...form.getInputProps('location')}
        />

        <Group position='left'>

        <TextInput
          placeholder="Төрөл"
          label="Төрөл"
          withAsterisk
          {...form.getInputProps('type')}
        />

        <TextInput
          placeholder="Материал"
          label="Материал"
          withAsterisk
          {...form.getInputProps('material')}
        />

        <TextInput
          placeholder="Он Цаг"
          label="Он Цаг"
          withAsterisk
          {...form.getInputProps('date')}
        />

        </Group>

        <Group position='left'>

        <TextInput
          placeholder="Голч"
          label="Голч"
          withAsterisk
          {...form.getInputProps('radius')}
        />

        <TextInput
          placeholder="Өргөн"
          label="Өргөн"
          withAsterisk
          {...form.getInputProps('width')}
        />

        <TextInput
          placeholder="Жин"
          label="Жин"
          withAsterisk
          {...form.getInputProps('weight')}
        />

        </Group>
        <Group position='left'>
        <Select
          label="Музей"
          value={museum} onChange={setMuseum} data={
            museums.map( h => {
              return {
                value: h._id.toString(),
                label: h.name
              }
            })
          } />

        <Select
          label="Танхим"
          value={hall} onChange={setHall} data={
            halls.map( h => {
              return {
                value: h._id.toString(),
                label: h.name
              }
            })
          } />
        </Group>

        <Divider my="sm" />
        <Text size="md">
          Нэмэлт зураг
        </Text>
        { state.length > 0 && <DndListHandle state={state} handlers={handlers} /> }
        <DropzoneButton state={state} handlers={handlers}/>
        <Divider my="sm" />
        <Text size="md">
          Нэмэлт аудио
        </Text>
        { audio.length > 0 && <DndListHandle state={audio} handlers={setAudio} /> }
        <DropZoneAudio state={audio} handlers={setAudio}/>
        <Divider my="sm" />
        <Text size="md">
          Тайлбар
        </Text>
        <Tabs defaultValue="mn">
          <Tabs.List>
            <Tabs.Tab value="mn">Монгол</Tabs.Tab>
            <Tabs.Tab value="en">Англи</Tabs.Tab>
            <Tabs.Tab value="ru">Орос</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="mn" pt="xs">
            <RichTextEditor 
                  formats={['bold', 'italic', 'underline', 'image', 'video']}
                  controls={[['bold', 'italic', 'underline', 'image', 'video']]}
                  value={mnValue} onChange={onChangeMN} id="rte" styles={{ root: { minHeight: 300 } }} onImageUpload={handleImageUpload}  />
          </Tabs.Panel>

          <Tabs.Panel value="en" pt="xs">
            <RichTextEditor 
                  formats={['bold', 'italic', 'underline', 'image', 'video']}
                  controls={[['bold', 'italic', 'underline', 'image', 'video']]}
                  value={enValue} onChange={onChangeEN} id="rte" styles={{ root: { minHeight: 300 } }} onImageUpload={handleImageUpload}  />
          </Tabs.Panel>

          <Tabs.Panel value="ru" pt="xs">
            <RichTextEditor 
                  formats={['bold', 'italic', 'underline', 'image', 'video']}
                  controls={[['bold', 'italic', 'underline', 'image', 'video']]}
                  value={ruValue} onChange={onChangeRU} id="rte" styles={{ root: { minHeight: 300 } }} onImageUpload={handleImageUpload} />
          </Tabs.Panel>
        </Tabs>
          </Tabs.Panel>

          <Tabs.Panel value="multiple" pt="xs">
            <MultipleExh state={exhibitions} handlers={exhHandlers}/>
            <Divider my="sm" />
            <Button type='button' onClick={addNewNegj}>
              Нэмэх
            </Button>
          </Tabs.Panel>
        </Tabs>
        <Divider my="sm" />
        <Button type='submit'>
          Хадгалах
        </Button>
      </Stack>
    </form>
    </Card>
    </div>
  );
}