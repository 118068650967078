// @ts-nocheck
import { useCallback, useRef  } from 'react';
import { Text, Group, createStyles } from '@mantine/core';
import { Dropzone, FileWithPath, MIME_TYPES } from '@mantine/dropzone';
import { IconX, IconDownload } from '@tabler/icons';
import Resizer from "react-image-file-resizer";
import { useRealmApp } from '../api/RealmApp';

const useStyles = createStyles((theme) => ({

  wrapper: {
    position: 'relative',
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 16,
    height: '100%',
  },

  icon: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
  deleteIcon: {
    position: 'absolute',
    top:-20,
    right: -20
  }
}));

export function DropzoneButton({ handlers }) {
  const { classes, theme } = useStyles();
  const openRef = useRef<any>(null);
  const realmApp = useRealmApp();


  const addFiles = async (file : FileWithPath[]) => {
    for(const f of file) {
      handlers.append(f);
      const resized = await resizeFile(f)            
      handleImageUpload(resized).then(url => {
        handlers.applyWhere(
          (item) => item.path && item.path === f.path,
          () => (url)
        );
      })
    }
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1280,
        960,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
  });

  const handleImageUpload = useCallback(
    (file : any) =>
      new Promise((resolve, reject) => {
        // Get a reference to the file
        // Encode the file using the FileReader API
        const reader = new FileReader();
        reader.onloadend = () => {
            // @ts-ignore
            const base64String = reader.result
            // @ts-ignore
            .replace('data:', '')
            .replace(/^.+,/, '');
            // @ts-ignore
            realmApp.currentUser.callFunction('uploadImageToS3', base64String)
            .then((response : any) => {
              resolve(response.url)
            })
            .catch((e) => {
              console.log(e);
              reject(new Error('Upload failed'))
            });
        };
        reader.readAsDataURL(file);
      }),
    []
  );

  return (
    <div className={classes.wrapper}>
      <Dropzone
        openRef={openRef}
        onDrop={addFiles}
        className={classes.dropzone}
        radius="md"
        accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
        maxSize={30 * 1024 ** 2}
      >
        <Group position="center">
          <Dropzone.Accept>
            <IconDownload size={50} color={theme.colors[theme.primaryColor][6]} stroke={1.5} />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX size={50} color={theme.colors.red[6]} stroke={1.5} />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconDownload size={50} color={theme.colors[theme.primaryColor][6]} stroke={1.5} />
          </Dropzone.Idle>
        </Group>

        <Text align="center" weight={700} size="lg" mt="xl">
          <Dropzone.Accept>Зураг зөөнө үү</Dropzone.Accept>
          <Dropzone.Reject>Зураг хэтэрхий том байна</Dropzone.Reject>
          <Dropzone.Idle>Зураг нэмэх</Dropzone.Idle>
        </Text>
      </Dropzone>
    </div>
  );
}