import { useState } from "react";

import { RealmAppProvider, useRealmApp } from "./api/RealmApp";
import realmJson from "./api/realm.json";

import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";

import { MantineProvider, ColorSchemeProvider, ColorScheme } from '@mantine/core';


import Root from "./routes/Root";
import Museums from "./routes/Museums";
import ErrorPage from "./routes/ErrorPage";
import { AddMuseum } from "./routes/AddMuseum";
import Users from "./routes/Users";
import Halls from "./routes/Halls";
import Exhibitions from "./routes/Exhibitions";
import News from "./routes/News";
import { AuthenticationTitle } from "./routes/Auth";
import { AddExhibition } from "./routes/AddExhibition";
import { AddHall } from "./routes/AddHall";
import { AddCollection } from "./routes/AddCollection";
import Collections from "./routes/Collections";
import { AddNews } from "./routes/AddNews";
import Videos from "./routes/Videos";
import { AddVideos } from "./routes/AddVideos";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: "museums",
        element: <ErrorPage />,
      },
      {
        path: "museums/new",
        element: <ErrorPage />,
      },
      {
        path: "users",
        element: <ErrorPage />,
      },
      {
        path: "users/new",
        element: <ErrorPage />,
      },
      {
        path: "halls",
        element: <Halls />,
      },
      {
        path: "halls/:id",
        element: <AddHall />,
      },
      {
        path: "halls/new",
        element: <AddHall />,
      },
      {
        path: "exhibitions",
        element: <Exhibitions />,
      },
      {
        path: "exhibitions/:id",
        element: <AddExhibition />,
      },
      {
        path: "exhibitions/new",
        element: <AddExhibition />,
      },
      {
        path: "news",
        element: <News />,
      },
      {
        path: "news/:id",
        element: <AddNews />,
      },
      {
        path: "news/new",
      element: <AddNews />,
      },
      {
        path: "collections",
        element: <Collections />,
      },
      {
        path: "collections/:id",
        element: <AddCollection />,
      },
      {
        path: "collections/new",
        element: <AddCollection />,
      },
      {
        path: "videos",
        element: <Videos />,
      },
      {
        path: "videos/:id",
        element: <AddVideos />,
      },
      {
        path: "videos/new",
        element: <AddVideos />,
      },
    ],
  },
  {
    path: "/login",
    element: <AuthenticationTitle />
  }
]);

export default function AppWithRealm() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>('dark');
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  return (
    <RealmAppProvider appId={realmJson.appId}>
      <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            colorScheme
          }}>
          <RouterProvider router={router} />
        </MantineProvider>
      </ColorSchemeProvider>
    </RealmAppProvider>
  );
}
