import { useState } from 'react';
import { 
    AppShell, 
    Header,
    MediaQuery,
    Burger,
    Text,
    useMantineTheme,
  } from '@mantine/core';
import { Outlet, Navigate } from "react-router-dom";
import { useRealmApp } from '../api/RealmApp';
import { NavbarNested } from '../components/NavBar';
  
function Root() {
  const { currentUser } = useRealmApp();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <AppShell
      padding="md"
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={<NavbarNested opened={opened} setOpened={setOpened} />}
      styles={(theme) => ({
        main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
      })}
      header={
        <Header
          height={60}
          p="md"
        >
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>

            <Text>Zev - Museum Management</Text>
          </div>
        </Header>
      }
    >
      { <Outlet /> }
    </AppShell>
  );
}
  
  export default Root;