import React from "react";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../api/RealmApp";
import realmJson from "../api/realm.json";
import * as Realm from "realm-web";

export function useVideos() {
  // Set up a list of todos in state
  const realmApp = useRealmApp();
  const [videos, setVideos] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // Get a client object for the todo task collection
  const videosCollection = useCollection({
    cluster: realmJson.dataSourceName,
    db: "zev",
    collection: "Videos",
  });

  // Fetch all todos on load and whenever our collection changes (e.g. if the current user changes)
  React.useEffect(() => {
    videosCollection.find({ _partition: realmApp.currentUser.id}).then((fetchedVideos) => {
      setVideos(fetchedVideos);
      setLoading(false);
    });
  }, [videosCollection]);


  const getVideosById = async (id) => {
    const current = await videosCollection.findOne({_id: new Realm.BSON.ObjectID(id)})
    return current
  }

  // Given a draft todo, format it and then insert it
  const saveVideos = async (draftVideo) => {
    draftVideo._partition = realmApp.currentUser.id;
    draftVideo.created_at = new Date();
    try {
      await videosCollection.updateOne({_id: draftVideo._id}, draftVideo, { upsert: true });
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a exhibition multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    }
  };

  const deleteVideos = async (id) => {
    await videosCollection.deleteOne({_id: id})
  }

  return {
    loading,
    videos,
    saveVideos,
    getVideosById,
    deleteVideos
  };
}
