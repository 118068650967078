import { Table, ActionIcon, Group, Image } from '@mantine/core';
import { IconPencil } from '@tabler/icons';
import { Link } from 'react-router-dom';

import { useCollections } from '../hooks/useCollections'; 

function Collections() {
  const { collections } = useCollections();
  
  const rows = collections.map((element)  => {
    return (
      <tr key={element._id.toString()}>
        <td>{element._id.toString()}</td>
        <td>{element.type}</td>
        <td>
          {
            element.image && element.image.length > 0 && 
          <Image
            height={50}
            width={50}
            fit="contain"
            radius="md"
            src={element.image[0]}
          />
          }
        </td>
        <td>
          <Group spacing={0} position="right">
            <Link to={`/collections/${element._id.toString()}`}>
              <ActionIcon>
                <IconPencil size={16} stroke={1.5} />
              </ActionIcon>
            </Link>
          </Group>
        </td>
      </tr>
    )
  });
  
  return (
    <Table>
      <thead>
          <tr>
          <th>ID</th>
          <th>Name</th>
          <th />
          <th />
          </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
  }
  
export default Collections;