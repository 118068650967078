import React from "react";
import { useWatch } from "./useWatch";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../api/RealmApp";
import realmJson from "../api/realm.json";
import * as Realm from "realm-web";

import {
  addValueAtIndex,
  replaceValueAtIndex,
  updateValueAtIndex,
  removeValueAtIndex,
  getItemIndex,
} from "./utils";

export function useExhibitions() {
  // Set up a list of todos in state
  const realmApp = useRealmApp();
  const [exhibitions, setExhibitions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // Get a client object for the todo task collection
  const exhibitionCollection = useCollection({
    cluster: realmJson.dataSourceName,
    db: "zev",
    collection: "Exhibition",
  });

  // Fetch all todos on load and whenever our collection changes (e.g. if the current user changes)
  React.useEffect(() => {
    exhibitionCollection.find({ _partition: realmApp.currentUser.id}).then((fetchedExhibitions) => {
        setExhibitions(fetchedExhibitions);
      setLoading(false);
    });
  }, [exhibitionCollection]);

  // Use a MongoDB change stream to reactively update state when operations succeed
  // useWatch(exhibitionCollection, {
  //   onInsert: (change) => {
  //       setExhibitions((oldExhibitions) => {
  //       if (loading) {
  //         return oldExhibitions;
  //       }
  //       const idx =
  //         getItemIndex(oldExhibitions, change.fullDocument) ?? oldExhibitions.length;
  //       if (idx === oldExhibitions.length) {
  //         return addValueAtIndex(oldExhibitions, idx, change.fullDocument);
  //       } else {
  //         return oldExhibitions;
  //       }
  //     });
  //   },
  //   onUpdate: (change) => {
  //       setExhibitions((oldExhibitions) => {
  //       if (loading) {
  //         return oldExhibitions;
  //       }
  //       const idx = getItemIndex(oldExhibitions, change.fullDocument);
  //       return updateValueAtIndex(oldExhibitions, idx, () => {
  //         return change.fullDocument;
  //       });
  //     });
  //   },
  //   onReplace: (change) => {
  //       setExhibitions((oldExhibitions) => {
  //       if (loading) {
  //         return oldExhibitions;
  //       }
  //       const idx = getItemIndex(oldExhibitions, change.fullDocument);
  //       return replaceValueAtIndex(oldExhibitions, idx, change.fullDocument);
  //     });
  //   },
  //   onDelete: (change) => {
  //       setExhibitions((oldExhibitions) => {
  //       if (loading) {
  //         return oldExhibitions;
  //       }
  //       const idx = getItemIndex(oldExhibitions, { _id: change.documentKey._id });
  //       if (idx >= 0) {
  //         return removeValueAtIndex(oldExhibitions, idx);
  //       } else {
  //         return oldExhibitions;
  //       }
  //     });
  //   },
  // });

  // Given a draft todo, format it and then insert it
  const saveExhibition = async (draftExhibition) => {
    draftExhibition._partition = realmApp.currentUser.id;
    try {
      await exhibitionCollection.updateOne({_id: draftExhibition._id}, draftExhibition, { upsert: true });
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a exhibition multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    }
  };

  const getExhibitionById = async (id) => {
    const current = await exhibitionCollection.findOne({_id: new Realm.BSON.ObjectID(id)})
    return current
  }

  return {
    loading,
    exhibitions,
    saveExhibition,
    getExhibitionById
  };
}
