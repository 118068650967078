import { Table, Anchor, ActionIcon, Group, Skeleton, Container, Select, Button, Pagination, Center , Space} from '@mantine/core';
import { useExhibitions } from '../hooks/useExhibitions'; 
import { IconPencil } from '@tabler/icons';
import { QRCode } from 'react-qrcode-logo';
import { useHalls } from '../hooks/useHalls';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import BlackQr from '../components/BlackQR';
import { Link } from 'react-router-dom';

const links = {
  "6363fbe1c633158b369700ad": "https://qr.chinggismuseum.com/post/",
  "636a2a97d2c55fda58f9cde3": "https://umnugovi.zevcommunity.org/post/",
  "6376f10ee0851ee207c8b004": "https://qr.ndaatgal.mn/post/",
  "63846b1a62077385234bbea7": "https://qr.zevcommunity.org/post/",
  "63e080429cead592ddca5f42": "https://templemuseum.mn/post/",
  "64b5062986f7869125ed69ac": "https://qr.jargaltkhaan.zevcommunity.org/post/",
  "661c58421f90ac3bcf2ee5bb": "https://nhm.zevcommunity.org/post/",
  "664cf50fb66908441051362e": "https://mongolbank.zevcommunity.org/post/",
  "664cf51fb669084410513632": "https://astropark.zevcommunity.org/post/",
  "66afe1854232f5d7b07e615b": "https://berkh.zevcommunity.org/post/",
  "6735e04372762d130aba28b9": "https://portable.zevcommunity.org/post/",
}

const logos = {
  "6363fbe1c633158b369700ad": require('../logo.png'),
  "636a2a97d2c55fda58f9cde3": require('../logo-umnugovi.png'),
  "6376f10ee0851ee207c8b004": require('../logo-ndsh1.png'),
  "63846b1a62077385234bbea7": require('../logo-dummy.png'),
  "63e080429cead592ddca5f42": require('../logo_mn.png'),
  "64b5062986f7869125ed69ac": require('../logo-jargal.jpg'),
  "661c58421f90ac3bcf2ee5bb": require('../logo-nhm.jpeg'),
  "664cf50fb66908441051362e": require('../logo-mongolbank.png'),
  "664cf51fb669084410513632": require('../logo-astro.png'),
  "66afe1854232f5d7b07e615b": require('../logo-berkh.png'),
  "6735e04372762d130aba28b9": require('../logo-portable.png')
}

function Exhibitions() {
  const { loading, exhibitions } = useExhibitions();
  const [hall, setHall] = useState('');
  const { halls } = useHalls();


  const hallData = useRef([]);
  const [page, setPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);

  const componentBlack = useRef();


  const handlePrintBlack = useReactToPrint({
    content: () => componentBlack.current,
  });

  const filterHall = (value) => {
    setHall(value)
  }

  useEffect(() => {
    if(hall !== '') {
      hallData.current = exhibitions.filter(x => x.hall_id === hall);
      if(page === 1) setCurrentData(hallData.current.slice(0, 12));
      setPage(1);
    }
  }, [hall])

  useEffect(() => {
    setCurrentData(hallData.current.slice((page - 1 ) * 12, (page - 1 ) * 12 + 12));
  }, [page])

  const rows = currentData.map((element)  => {
    return (
      <tr key={element._id.toString()}>
        <td>
          <Anchor component="a" href={ links[element.museum_id] + element._id.toString() } target="_blank">
            {element.name}
          </Anchor>
        </td>
        <td>
          {element.type}
        </td>
        <td>
          {element.location}
        </td>
        <td style={{ width: 100}}>
          <QRCode 
            value={ links[element.museum_id] + element._id.toString() }
            logoImage={logos[element.museum_id]}
            removeQrCodeBehindLogo
            qrStyle={'dots'}
            logoWidth={88.19}
            size={264.57}
            bgColor="#ffffff"
            fgColor="#000000"
            eyeRadius={10}
            ecLevel='H'
            quietZone={10}
            // qrStyle={'squares'}
            // logoWidth={46}
            // bgColor="#ffffff"
            // fgColor="#000000"
            // eyeRadius={1}
            // ecLevel='L'
            // quietZone={10}
            // size={200}
          />
        </td>
        <td>
          <Group spacing={0} position="right">
            <Link to={`/exhibitions/${element._id.toString()}`}>
              <ActionIcon>
                <IconPencil size={16} stroke={1.5} />
              </ActionIcon>
            </Link>
          </Group>
        </td>
      </tr>
    )
  });
  
  const rowsLoading = Array(10).fill(0).map((element, index)  => {
    return (
      <tr key={index}>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
      </tr>
    )
  });

  return (
    <Container>
      <div style={{display: 'none'}}>
        <BlackQr data={currentData} ref={componentBlack}></BlackQr>
      </div> 
        <Group position='apart'>
          <Select
          label="Танхим"
          value={hall} onChange={filterHall} data={
            halls.map( h => {
              return {
                value: h._id.toString(),
                label: h.name
              }
            })
          } />
          <Group position='right'>
            <Button onClick={handlePrintBlack}>
              Хар QR
            </Button>
          </Group>
        </Group>

        <Space h="xl" />

        { currentData.length > 0 && 
          <Center>
            <Pagination 
            page={page} 
            onChange={setPage}
            total={
              Math.ceil(hallData.current.length / 12)
            }
            /> 
          </Center>
        }

        <Space h="xl" />


        <Table>
      <thead>
            <tr>
              <th>Үзмэрийн нэр</th>
              <th>Үзмэрийн төрөл</th>
              <th>Үзмэрийн танхим</th>
              <th />
              <th />
            </tr>
        </thead>
        <tbody>
          {loading ? rowsLoading : rows}
        </tbody>
      </Table>
      <Space h="xl" />

      { currentData.length > 0 && 
        <Center>
          <Pagination 
          page={page} 
          onChange={setPage}
          total={
            Math.ceil(hallData.current.length / 12)
          }
          /> 
        </Center>
      }
    </Container>
  );
}
  
export default Exhibitions;
