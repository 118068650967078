import {
    TextInput,
    PasswordInput,
    Paper,
    Title,
    Container,
    Button,
    LoadingOverlay,
    Center
  } from '@mantine/core';
import { useRealmApp } from '../api/RealmApp';
import { useForm } from '@mantine/form';
import { Outlet, Navigate } from "react-router-dom";
import * as Realm from "realm-web";
import { useState } from 'react';

interface LoginValues {
  email: string,
  password: string
}

  export function AuthenticationTitle() {
    const { logIn, currentUser } = useRealmApp();
    const [visible, setVisible] = useState(false);

    const form = useForm({
      initialValues: {
        email: '',
        password: '',
      },
      validate: {
        email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      },
    });

    const createAccount = async ({email, password} : LoginValues) => {
      setVisible(true);
      // @ts-ignore
      await logIn(Realm.Credentials.emailPassword(email, password));

    }

    if (currentUser) {
      return <Navigate to="/" />;
    }

    return (
      <Center style={{height: '100vh'}}>
        <Container fluid pb={100}>
          <Title
            align="center"
            sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
          >
            Zev Museum Management
          </Title>
          <div style={{position: 'relative' }}>
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <form onSubmit={form.onSubmit(createAccount)}>
                <LoadingOverlay visible={visible} overlayBlur={2} />
                <TextInput 
                  label="Email" 
                  placeholder="admin@zevcommunity.org" 
                  required 
                  {...form.getInputProps('email')}
                />
                <PasswordInput 
                  label="Password" 
                  placeholder="Your password" 
                  required 
                  mt="md"
                  {...form.getInputProps('password')}
                />
                <Button type="submit" fullWidth mt="xl">
                  Sign in
                </Button>
              </form>
            </Paper>
          </div>
        </Container>
      </Center>
    );
  }