import { createStyles, Card, TextInput, Text, Stack, Divider, Tabs, Group, Button, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState, useEffect } from 'react';
import * as Realm from "realm-web";
import { useNavigate, useParams } from 'react-router-dom';
import { Select } from '@mantine/core';

import { useHalls } from '../hooks/useHalls'; 
import { useMuseums } from '../hooks/useMuseums';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },
  title: {
    lineHeight: 1,
  },
}));

export function AddHall() {
  let { id } = useParams();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const { museums } = useMuseums();
  const [museum, setMuseum] = useState('');
  
  const { saveHall, getHallsByid } = useHalls();
  const form = useForm({
    initialValues: {
      name: '',
      background_color: '',
      foreground_color: '',
      border_color: '',
    },
  });

  useEffect(() => {
    if(id) {
      getHallsByid(id).then(exh => {
        form.setValues(exh);
        setMuseum(exh.museum_id);
      })
    }
  }, [id])

  const addNewHall = async (values) => {
    setVisible(true);
    await saveHall(
      {
        ...values, 
        _id: new Realm.BSON.ObjectID(),
        museum_id: museum
      }
    )
    navigate('/halls');
  }


  return (
    <div style={{position: 'relative'}}>
    <Card withBorder radius="md" p="xl" className={classes.card}>
      <form onSubmit={form.onSubmit(addNewHall)}>

      <LoadingOverlay visible={visible} overlayBlur={2} />

      <Text size="xl" className={classes.title} weight={'bold'}>
        Тэнхим нэмэх
      </Text>
      <Divider my="sm" />
      <Stack>
        
        <Select
          label="Музей"
          value={museum} onChange={setMuseum} data={
            museums.map( h => {
              return {
                value: h._id.toString(),
                label: h.name
              }
            })
          } />

        <TextInput
          placeholder="Тэнхимийн нэр"
          label="Тэнхимийн нэр"
          withAsterisk
          {...form.getInputProps('name')}
        />

        <TextInput
          placeholder="Арын өнгө"
          label="Арын өнгө"
          {...form.getInputProps('background_color')}
        />

        <TextInput
          placeholder="Текст өнгө"
          label="Текст өнгө"
          {...form.getInputProps('foreground_color')}
        />

        <TextInput
          placeholder="Хүрээний өнгө"
          label="Хүрээний өнгө"
          {...form.getInputProps('border_color')}
        />

        <Divider my="sm" />
        <Button type='submit'>
          Хадгалах
        </Button>
      </Stack>
    </form>
    </Card>
    </div>
  );
}