import { createStyles, Card, TextInput, Text, Stack, Divider, Tabs, Group, Button, LoadingOverlay, Textarea, Switch } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconX, IconCheck } from '@tabler/icons';

import { useEffect, useState } from 'react';
import * as Realm from "realm-web";
import { useNavigate, useParams } from 'react-router-dom';
import { DropzoneButton } from '../components/DropZoneButton';
import { useCollections } from '../hooks/useCollections';
import { DndListHandle } from '../components/DndListHandle';
import { useListState } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },
}));

export function AddCollection() {
  let { id } = useParams();
  const { classes, theme } = useStyles();
  const { getCollectionById, saveCollection } = useCollections();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [state, handlers] = useListState([]);
  const [checked, setChecked] = useState(true);



  const form = useForm({
    initialValues:{
      type: '',
      name: '',
      size: '',
      material: '',
      period: '',
      name_en: '',
      description_en: '',
      size_en: '',
      material_en: '',
      period_en: '',
      is_show: checked,
    }
  });

  useEffect(() => {
    if(id) {
      getCollectionById(id).then(exh => {
        form.setValues(exh);
        if(exh.image) handlers.setState(exh.image)
      })
    }
  }, [id])

  const addNewCollection = async (values) => {
    setVisible(true);
    await saveCollection(
      {
        ...values, 
        _id: id ? new Realm.BSON.ObjectID(id) : new Realm.BSON.ObjectID(),
        image: state.filter(x => x.includes('http'))
      }
    )
    navigate('/collections');
  }
  return (
    <div style={{position: 'relative'}}>
      <Card withBorder radius="md" p="xl" className={classes.card}>
        <form onSubmit={form.onSubmit(addNewCollection)}>
        <LoadingOverlay visible={visible} overlayBlur={2} />
          <Group>
          <Text size="md" className={classes.title} weight={'bold'}>
            Цуглуулга нэмэх
          </Text>

            <Switch
              checked={checked}
              onChange={(event) => setChecked(event.currentTarget.checked)}
              size="md"
              label="Веб дээр харуулах"
              thumbIcon={
                checked ? (
                  <IconCheck size={12} color={theme.colors.teal[theme.fn.primaryShade()]} stroke={3} />
                ) : (
                  <IconX size={12} color={theme.colors.red[theme.fn.primaryShade()]} stroke={3} />
                )
              }
            />
          </Group>

          <Divider my="sm" />
        
          <Tabs defaultValue="mn">
            <Tabs.List grow>
              <Tabs.Tab value="mn">Монгол мэдээлэл</Tabs.Tab>
              <Tabs.Tab value="en">Англи мэдээлэл</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="mn" pt="xs">
            <Stack>
                <TextInput
                  placeholder="Цуглуулгын нэр"
                  label="Цуглуулгын нэр"
                  withAsterisk
                  {...form.getInputProps('type')}
                />

                <Textarea
                    placeholder="Монгол Тайлбар"
                    label="Монгол Тайлбар"
                    autosize
                    minRows={10}
                    {...form.getInputProps('name')}
                  />
                <Group grow>
                  <TextInput
                    placeholder="Хэмжээ"
                    label="Хэмжээ"
                    {...form.getInputProps('size')}
                  />
                  <TextInput
                    placeholder="Материал"
                    label="Материал"
                    {...form.getInputProps('material')}
                  />
                  <TextInput
                    placeholder="Цаг үе"
                    label="Цаг үе"
                    {...form.getInputProps('period')}
                  />
                </Group>
              </Stack>
            </Tabs.Panel>

            <Tabs.Panel value="en" pt="xs">
              <Stack>
                <TextInput
                  placeholder="Цуглуулгын нэр"
                  label="Цуглуулгын нэр"
                  {...form.getInputProps('name_en')}
                />

                <Textarea
                    placeholder="Монгол Тайлбар"
                    label="Монгол Тайлбар"
                    autosize
                    minRows={10}
                    {...form.getInputProps('description_en')}
                  />
                <Group grow>
                  <TextInput
                    placeholder="Хэмжээ"
                    label="Хэмжээ"
                    {...form.getInputProps('size_en')}
                  />
                  <TextInput
                    placeholder="Материал"
                    label="Материал"
                    {...form.getInputProps('material_en')}
                  />
                  <TextInput
                    placeholder="Цаг үе"
                    label="Цаг үе"
                    {...form.getInputProps('period_en')}
                  />
                </Group>
              </Stack>
            </Tabs.Panel>
          </Tabs>

          <Divider my="sm" />
          <Text size="md">
            Зурагууд
          </Text>
          { state.length > 0 && <DndListHandle state={state} handlers={handlers} /> }
          <DropzoneButton state={state} handlers={handlers}/>
          <Divider my="sm" />
          <Button fullWidth type='submit'>
            Хадгалах
          </Button>
        </form>
      </Card>
    </div>
  );
}